const Portfolio_data = [
  {
    id: 1,
    category: "mapty",
    totalLike: "3",
    title: "An application for Walking and Cycling ",
    image: "./image/Mapty.png",
    address: "https://m98hs98.github.io/mapty/",
    customURL: "http://mahsa.dev/mapty",
    description: "This application is a JavaScript program for a fitness tracking application called Mapty. It defines classes for workouts, specifically for running and cycling, with properties and methods to calculate pace, speed, and workout descriptions. The program utilizes a map library (Leaflet) to display the map, capture user input through a form, and render workout markers. It also implements local storage to persist workout data.",
  },
  {
    id: 2,
    category: "Bankist",
    totalLike: "4",
    title: "An app for bank accounting",
    image: "./image/Bankist.png",
    address: "https://m98hs98.github.io/Bankist/",
    customURL: "http://mahsa.dev/Bankist",
    description: "The provided code is a Bank Application with features for user banking operations. It handles logging in, money transfers, loan requests, account closure, and movement sorting. The JavaScript code manages user interactions, data manipulation, and UI updates. It includes functions for formatting, displaying movements, calculating balances, and creating usernames. The code represents a basic banking system with account management and transaction capabilities. (User: js Password: 1111)"
  },
  {
    id: 3,
    category: "Omnifood",
    totalLike: "3",
    title: "An application for ordernig healthy food",
    image: "./image/Omnifood.png",
    address: "https://m98hs98.github.io/Omnifood/",
    customURL: "http://mahsa.dev/Omnifood",
    description: "The code enhances a nutrition application using JavaScript. It adds a sticky navigation bar, smooth scrolling animations, and animations for visible elements. It also includes a mobile navigation feature. These interactive features improve the usability and engagement of the application.",
  },
  {
    id: 4,
    category: "Pig game",
    totalLike: "0",
    title: "Playing game",
    image: "./image/pigGame.png",
    address: "https://m98hs98.github.io/Pig-Game/",
    customURL: "http://mahsa.dev/Pig-Game",
    description: "The code implements a Pig game using JavaScript. It handles initializing the game, rolling the dice, updating scores, switching players, holding scores, and resetting the game. The game involves rolling a dice, accumulating scores, and reaching a winning score of 20. It provides the necessary functionality for playing and managing the game.",
  },
  {
    id: 5,
    category: "Search the image",
    totalLike: "10",
    title: "Playing game",
    image: "./image/searchImage.png",
    address: "https://remarkable-gingersnap-29121b.netlify.app",
    customURL: "http://mahsa.dev/SearchImage",
    description: "The provided code is a React application for searching images. It consists of several components: 'App', 'ImageCard', 'ImageList', and 'SearchBar'. The 'App' component handles the state and API integration, while the 'ImageCard' component displays individual images with their descriptions. The 'ImageList' component maps the image results to 'ImageCard' components. The 'SearchBar' component accepts user input for image search terms. The 'index.js' file renders the 'App' component to the HTML element with the id of 'root', and the 'unsplash.js' file provides the necessary configuration for making requests to the Unsplash API. Overall, this code creates an image search application using React.",
  },
  {
    id: 6,
    category: "YouTube Videos",
    totalLike: "11",
    title: "Searching YouTube Videos with Hooks",
    image: "./image/yt-videoHooks.png",
    address: "https://yt-videos-hooks-steel.vercel.app",
    customURL: "http://mahsa.dev/ytVideos",
    description: "It is a streamlined web application, leveraging React hooks and the YouTube API for efficient video search and optimal user experience. Its modular architecture and responsive design ensure swift content delivery and seamless adaptability across devices.",
  },
  {
    id: 7,
    category: "ToDo List",
    totalLike: "4",
    title: "",
    image: "./image/ToDoList.png",
    address: "https://6513b26698d3ea534c7f3dbb--polite-moonbeam-a9f048.netlify.app",
    customURL: "http://mahsa.dev/ToDoList",
    description: "",
  },
  {
    id: 8,
    category: "Automation App",
    totalLike: "12",
    title: "",
    image: "./image/AutomationApp.png",
    address: "https://calm-gnome-35d962.netlify.app",
    customURL: "http://mahsa.dev/AutomationApp",
    description: "The provided code describes a React web application that allows users to search for URLs from fetched web content. Users can specify the number of results they wish to see, enter a keyword, and a domain name. The app then retrieves relevant URLs, excluding a set of predefined domains, mostly related to Google services. Additionally, it validates the format of the entered domain to ensure it's correct. Alongside the app, there's a proxy setup which directs certain requests to an external server, enabling the fetching of the necessary content.",
  },
  {
    id: 9,
    category: "Drag & Drop",
    totalLike: "5",
    title: "",
    image: "./image/Drag&Drop.png",
    address: "",
    customURL: "http://mahsa.dev/Drag&Drop",
    description: "This app allows users to input and manage projects, categorizing them into 'active' and 'finished' lists. Users can transition project status via drag-and-drop. State management ensures consistent data display. Decorators and utility functions help streamline event handling and validation.",
  },
];

export default Portfolio_data;